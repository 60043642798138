import React from 'react';
import { Menu } from 'antd';

import {
	TeamOutlined,
	VerifiedOutlined,
	BarsOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const MenuLateral: React.FC = () => {
	return (
		<Menu
			mode="inline"
			defaultOpenKeys={['sub1']}
			style={{ background: 'none' }}
			selectable={false}
		>
			<Menu.ItemGroup key="sub1" title="Cadastros">
				<Menu.Item icon={<TeamOutlined />} key="1">
					<Link to="usuarios">Usuários</Link>
				</Menu.Item>
				<Menu.Item icon={<VerifiedOutlined />} key="2">
					Administradores
				</Menu.Item>
			</Menu.ItemGroup>
			<Menu.ItemGroup key="sub2" title="Cursos">
				<Menu.Item key="5" icon={<BarsOutlined />}>
					<Link to="cursos">Listar</Link>
				</Menu.Item>
			</Menu.ItemGroup>
		</Menu>
	);
};

export default MenuLateral;
