import React from 'react';
import {
	Route as ReactDOMRoute,
	RouteProps as ReactDOMRouteProps,
	Redirect,
} from 'react-router-dom';

import DefaultLayout from '../layouts/Default';

interface RouteProps extends ReactDOMRouteProps {
	isPrivate?: boolean;
	component: React.ComponentType;
	layout?: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
	isPrivate = false,
	component: Component,
	layout: Layout = DefaultLayout,
	...rest
}) => {
	const token = sessionStorage.getItem('@studio:token');
	const isSigned = token !== 'undefined' && token !== null;

	return (
		<ReactDOMRoute
			{...rest}
			render={({ location }) => {
				return isPrivate === isSigned ? (
					<Layout>
						<Component />
					</Layout>
				) : (
					<Redirect
						to={{
							pathname: isPrivate ? '/' : '/dashboard',
							state: { from: location },
						}}
					/>
				);
			}}
		/>
	);
};

export default Route;
