/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { notification } from 'antd';
import React from 'react';

export interface Http {
	success: boolean;
	message: string;
	data: object;
}

const api = axios.create({
	// baseURL: 'https://localhost:5001',
	baseURL: 'https://cursos-api.studiodigitalit.com',
});

export const HttpPost = async (resource: string, body: object) => {
	try {
		const response: any = await api.post(resource, body);
		return response;
	} catch (error) {
		const errors: any = [];
		error.response.data.data.forEach((err: string) => {
			errors.push(React.createElement('li', { key: err }, err));
		});

		const reactElementUl = React.createElement(
			'ul',
			{ className: 'list-errors' },
			errors,
		);

		const duration = 6 + errors.length;

		if (error.response.data.status === 'error') {
			notification.error({
				message: error.response.data.message,
				description: reactElementUl,
				duration,
			});
		} else if (error.response.data.status === 'warning') {
			notification.warn({
				message: error.response.data.message,
				description: reactElementUl,
				duration,
			});
		} else {
			notification.info({
				message: error.response.data.message,
				description: reactElementUl,
				duration,
			});
		}

		return null;
	}
};
