export const size = {
	mobile: '768px',
	tablet: '992px',
	desktop: '1200px',
	large: '1600px',
};

export const device = {
	xs: `(max-width: ${size.mobile})`,
	sm: `(min-width: ${size.mobile})`,
	md: `(min-width: ${size.tablet})`,
	lg: `(min-width: ${size.desktop})`,
	xl: `(min-width: ${size.large})`,
};
