import React from 'react';

import Header from './Header';
import Content from './Content';

const DefaultLayout: React.FC = ({ children }) => {
	return (
		<>
			<Header />
			<Content>{children}</Content>
		</>
	);
};

export default DefaultLayout;
