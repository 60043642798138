import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';

import { LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/AuthContext';

const LoginForm: React.FC = () => {
	const { login } = useAuth();
	const [loading, setLoading] = useState(false);

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};

	const tailLayout = {
		wrapperCol: {
			offset: 8,
			span: 16,
		},
	};

	const onFinish = async (values: object) => {
		setLoading(true);
		await login(values);
		setLoading(false);
	};

	return (
		<Form {...layout} onFinish={onFinish}>
			<Form.Item
				label="E-mail"
				name="email"
				rules={[{ required: true, message: 'Informe o e-mail' }]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Senha"
				name="senha"
				rules={[{ required: true, message: 'Informe a senha' }]}
			>
				<Input.Password />
			</Form.Item>

			<Form.Item {...tailLayout}>
				<Button
					type="primary"
					loading={loading}
					htmlType="submit"
					icon={<LogoutOutlined />}
				>
					Entrar
				</Button>
			</Form.Item>
		</Form>
	);
};

export default LoginForm;
