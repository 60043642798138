import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

	@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i');

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: 0;
	}

	body {
		background:#f1f2f5;
		color: #444;
		-webkit-font-smoothing: antialiased;
	}

	body, input, button {
		font-family: 'Montserrat', sans-serif;
		font-size:16px;
		color:#444;
	}

	h1, h2, h3, h4, h5, h6, strong {
		font-weight:500;
		color:#444;
	}

	h1 {
		font-weight:400;
		font-size:38px;
		border-bottom: 2px solid #DDD;
	}

	h2 {
		font-weight:300;
	}

	button {
		cursor: pointer;
	}

	.list-errors {
		list-style:none;
		margin:0;
	}

	.ant-form-item-explain {
		font-size:12px;
		font-weight:500 !important;
		margin-bottom:8px;
	}

	.container {
		width:100%;
		max-width: 1440px;
		margin:0 auto;
	}
`;
