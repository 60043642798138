import React, { useEffect } from 'react';

// import { Container } from './styles';

const Title: React.FC<{ text: string }> = ({ text }) => {
	const title = 'Site do Cliente';

	useEffect(() => {
		document.title = `${text} | ${title}`;
	}, [text]);

	return <div />;
};

export default Title;
