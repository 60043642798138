import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';

// import { Container } from './styles';

const Usuarios: React.FC = () => {
	return (
		<>
			<Breadcrumb>
				<Breadcrumb.Item>
					<HomeOutlined />
					<span>Home</span>
				</Breadcrumb.Item>
				<Breadcrumb.Item>Usuários</Breadcrumb.Item>
				<Breadcrumb.Item>Listar</Breadcrumb.Item>
			</Breadcrumb>
			<h1>Usuários</h1>
		</>
	);
};

export default Usuarios;
