import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { HttpPost } from '../services/api';

interface Auth {
	usuario: object;
	login(object: object): Promise<void>;
	logout(): void;
}

interface AuthState {
	token: string;
	usuario: object;
}

const AuthContext = createContext<Auth>({} as Auth);

export const AuthProvider: React.FC = ({ children }) => {
	const history = useHistory();

	const [data, setData] = useState<AuthState>(() => {
		const token = sessionStorage.getItem('@studio:token');
		const usuario = sessionStorage.getItem('@studio:usuario');

		if (token && usuario) {
			return { token, usuario: JSON.parse(usuario) };
		}

		return {} as AuthState;
	});

	const login = useCallback(
		async (object) => {
			const response = await HttpPost('v1/auth/login/admin', object);

			if (response) {
				const {
					nome,
					email: emailUsuario,
					role,
					master,
					token,
				} = response.data.data;

				sessionStorage.setItem('@studio:token', token);
				sessionStorage.setItem(
					'@studio:usuario',
					JSON.stringify({
						nome,
						email: emailUsuario,
						role,
						master,
					}),
				);

				setData({
					token,
					usuario: {
						nome,
						email: emailUsuario,
						role,
						master,
					},
				});

				history.push('/home');
			}
		},
		[history],
	);

	const logout = useCallback(() => {
		sessionStorage.removeItem('@studio:token');
		sessionStorage.removeItem('@studio:usuario');

		setData({} as AuthState);
		history.push('/');
	}, [history]);

	return (
		<AuthContext.Provider value={{ usuario: data.usuario, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export function useAuth(): Auth {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}

	return context;
}
