import React from 'react';
import { Col, Row, Card } from 'antd';
import LoginForm from './login-form';

const Login: React.FC = () => {
	return (
		<Row>
			<Col span={8} offset={8}>
				<p
					style={{
						height: '100px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					LOGO DO CLIENTE
				</p>
			</Col>
			<Col span={8} offset={8}>
				<Card title="Acesso ao Sistema">
					<LoginForm />
				</Card>
			</Col>
		</Row>
	);
};

export default Login;
