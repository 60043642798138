import React from 'react';
import { HashRouter } from 'react-router-dom';

import './App.css';

import Routes from './routes';

import GlobalStyle from './styles/global';
import { AuthProvider } from './contexts/AuthContext';

const App: React.FC = () => (
	<HashRouter>
		<AuthProvider>
			<Routes />
		</AuthProvider>
		<GlobalStyle />
	</HashRouter>
);

export default App;
