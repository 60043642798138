import styled from 'styled-components';
import { Button, Dropdown } from 'antd';
import { device, size } from '../../styles/device';

export const TopoDesktop = styled.div`
	width: 100%;
	background: #444;
	display: none;
	color: #fff;

	header {
		width: 100%;
		max-width: ${size.large};
		margin: 0 auto;
		display: flex;
		height: 70px;
		align-items: center;

		.logo {
			width: 280px;
		}

		.menu-superior {
			flex: 1;
			display: flex;
			justify-content: space-between;
		}
	}

	@media ${device.lg} {
		display: block;
	}
`;

export const ButtonLink = styled(Button)`
	color: #fff;
	&:hover {
		color: #ff0;
	}

	&:active,
	&:visited,
	&:focus {
		color: #fff;
	}
`;

export const DropdownLink = styled(Dropdown)`
	color: #fff;

	&:hover {
		color: #ff0;
	}

	&:active,
	&:visited,
	&:focus {
		color: #fff;
	}
`;

export const AreaContent = styled.div`
	width: 100%;
	max-width: ${size.large};
	min-height: 500px;
	margin: 0 auto;
	display: flex;

	nav {
		width: 280px;
		min-height: 500px;
		background: linear-gradient(90deg, #f1f2f5, #e3e3e3);
		display: none;

		@media ${device.lg} {
			display: block;
		}
	}

	main {
		padding: 15px 25px;
		flex: 1;
	}
`;
