import React from 'react';
import { Button, Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/AuthContext';
import Title from '../../Components/Title';

const Home: React.FC = () => {
	const { logout } = useAuth();

	return (
		<>
			<Title text="Titulo da página" />
			<Breadcrumb>
				<Breadcrumb.Item>
					<HomeOutlined />
					<span>Home</span>
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1>Home</h1>
			<Button type="primary" onClick={logout}>
				Logout
			</Button>
		</>
	);
};

export default Home;
