import React from 'react';
import { Menu, Button } from 'antd';
import { UserOutlined, LogoutOutlined, HomeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { TopoDesktop, ButtonLink, DropdownLink } from './style';

const Header: React.FC = () => {
	const { logout } = useAuth();
	const history = useHistory();

	const menuDashboard = () => {
		history.push('dashboard');
	};

	const menuUsuario = (
		<Menu>
			<Menu.Item key="0">
				<a href="http://www.alipay.com/">Meus Dados</a>
			</Menu.Item>
			<Menu.Item key="1">
				<a href="http://www.taobao.com/">Alterar Senha</a>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="3" onClick={logout} icon={<LogoutOutlined />}>
				Sair
			</Menu.Item>
		</Menu>
	);

	return (
		<TopoDesktop>
			<header>
				<div className="logo">LOGO DO CLIENTE</div>

				<div className="menu-superior">
					<div className="esquerda">
						<ButtonLink
							type="link"
							onClick={menuDashboard}
							icon={<HomeOutlined />}
						>
							Home
						</ButtonLink>
					</div>

					<div className="direita">
						<DropdownLink overlay={menuUsuario} trigger={['click']}>
							<Button type="link" icon={<UserOutlined />}>
								Nome do Usuário
							</Button>
						</DropdownLink>
					</div>
				</div>
			</header>
		</TopoDesktop>
	);
};

export default Header;
