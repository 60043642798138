import React from 'react';

import { AreaContent } from './style';
import MenuLateral from './MenuLateral';

const Content: React.FC = ({ children }) => {
	return (
		<AreaContent>
			<nav>
				<MenuLateral />
			</nav>
			<main>{children}</main>
		</AreaContent>
	);
};

export default Content;
