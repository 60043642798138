import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import LoginLayout from '../layouts/Login';
import Usuarios from '../pages/Usuarios';
import Cursos from '../pages/Cursos';
import Home from '../pages/Home';

const Routes: React.FC = () => (
	<Switch>
		<Route path="/" exact component={Login} layout={LoginLayout} />
		<Route path="/home" component={Home} isPrivate />
		<Route path="/usuarios" component={Usuarios} isPrivate />
		<Route path="/cursos" component={Cursos} isPrivate />
	</Switch>
);

export default Routes;
